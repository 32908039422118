import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-student-testing-download-all',
  templateUrl: './student-testing-download-all.component.html',
  styleUrls: ['./student-testing-download-all.component.scss']
})
export class StudentTestingDownloadAllComponent implements OnInit {
  reportYear: string;
  iReadyTest: string;
  studentIds: any;
  public zip: any = JSZip();
  count: any = 0;
  downloadStatus = "Please wait download is in progress - 0%";
  tempStudentIds: any;
  numberOfReports: any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const encodedData = params['data'];
      if (encodedData) {
        try {
          const decodedData = JSON.parse(atob(encodedData));

            this.reportYear = decodedData.reportYearFromTeacher;
            this.iReadyTest = decodedData.testType;
            this.studentIds = decodedData.studentidIds.split(",");
            this.numberOfReports = this.studentIds.length;
            this.tempStudentIds = [...this.studentIds];
        }
        catch (error) {
          console.error("Failed to decode parameters", error);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.downloadPdfs();
  }

  async downloadPdfs() {
    var element = document.getElementById("studentreport-" + this.tempStudentIds[0]);
    const pdf = new jsPDF('p', 'mm', 'a4');
    const content = element;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Convert the content into a canvas
    const canvas = await html2canvas(content);
    const imgData = canvas.toDataURL('image/png');

    const contentWidth = canvas.width;
    const contentHeight = canvas.height;

    // Calculate the ratio and dimensions for the PDF
    const pdfWidth = pageWidth;
    const pdfHeight = (contentHeight * pdfWidth) / contentWidth;

    let position = 0;

    while (position < contentHeight) {
      const cropHeight = Math.min(contentHeight - position, pageHeight * (contentWidth / pageWidth));
      const canvasSlice = document.createElement('canvas');
      canvasSlice.width = canvas.width;
      canvasSlice.height = cropHeight;

      const ctx = canvasSlice.getContext('2d');
      if (ctx) {
        ctx.drawImage(
          canvas,
          0,
          position,
          canvas.width,
          cropHeight,
          0,
          0,
          canvas.width,
          cropHeight
        );
      }

      const sliceImgData = canvasSlice.toDataURL('image/png');
      pdf.addImage(sliceImgData, 'PNG', 0, 0, pdfWidth, (cropHeight * pdfWidth) / contentWidth);
      position += cropHeight;

      if (position < contentHeight) {
        pdf.addPage();
      }
    }

    const pdfBlob = pdf.output('blob');
    this.zip.file(`pdf-${this.tempStudentIds[0]}.pdf`, pdfBlob);
    this.count = this.count + 1;
    this.downloadStatus = `Please wait download is in progress - ${Math.round((100/this.numberOfReports)*this.count)}%`
    if (this.numberOfReports == this.count) {
      this.zip.generateAsync({ type: 'blob' }).then((blob) => {
        saveAs(blob, 'iReady-Student-Reports.zip');
        this.downloadStatus = "Download Completed";
      });
    }
    else {
      this.tempStudentIds.splice(0, 1);
      this.downloadPdfs();
    }
  }
}







