import { DashboardModel } from "./../../routes/student/dashboard-route/dashboard/models/DashboardModel";
import { ProfileService } from "./profile.service";
import { IRoleServices } from "./IRoleServices";
import { IEmployeeContact } from "./parent.services.models";
import { IStudentResume } from "./../components/student-list-widget/models/IStudentResume";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { OAuthService } from "angular-oauth2-oidc";
import { ContactModel } from "../components/contact-widget/models/ContactWidgetModel";
import { AppConfigService } from "src/app/services/app-config.service";
import { StudentMenuItem } from "../components/student-header-widget/models/Student-MenuItem";
import { StudentTransportRequest } from "../components/students-timeline/student-bus/models/Student-TransportRequest";
import { IStudentHealthConsent } from "../components/student-list-widget/models/IStudentHealthConsent";
import { CmdAddParentSurveyResponses } from "../components/parent-survey/parent-survey.component";
import { SurveyQuestion } from "src/app/routes/shared/survey-manager-route/newtab-view/newtab-view.component";

@Injectable({
  providedIn: "root",
})
export class ParentsService implements IRoleServices {
  baseUrl: string =this.appConfig.configuration.environment.endPoints.customerServices; //"https://localhost:44315"
  parentId: string = null;
  parentUsername: string = null;
  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private oauth: OAuthService
  ) {
    let identity = this.oauth.getIdentityClaims() as any;
    this.parentId = identity?.usernameNo;
    this.parentUsername = identity?.sub;
  }
  getContacts(id: string): Observable<ContactModel[]> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/contacts`;
    return this.api
      .get(path)
      .pipe(map((response) => response as ContactModel[]));
  }
  getStudentsByParent(): Observable<IStudentResume[]> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students`;
    return this.api
      .get(path)
      .pipe(map((response) => response as IStudentResume[]));
  }
  getStudentsByParentId(id): Observable<IStudentResume[]> {
    let path = `${this.baseUrl}/v1/parents/${id}/students`;
    return this.api
      .get(path)
      .pipe(map((response) => response as IStudentResume[]));
  }
  getStudentsByParentNoCacheAdmin(id): Observable<IStudentResume[]> {
    let path = `${this.baseUrl}/v1/parents/${id}/students`;
    return this.api
      .getNoCache(path)
      .pipe(map((response) => response as IStudentResume[]));
  }
  getStudentsByParentNoCache(): Observable<IStudentResume[]> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students`;
    return this.api
      .getNoCache(path)
      .pipe(map((response) => response as IStudentResume[]));
  }
  getSchoologyRemoteLogin(
    platform: string,
    schoologyId: string
  ): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/schoologyRemoteLogin?platform=${platform}&schoologyId=${schoologyId}`;
    return this.api.get(path).pipe(map((response) => response as any[]));
  }
  getSchoologyRemoteLoginAssigment(
    platform: string,
    schoologyId: string,
    taskId: string
  ): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/schoologyRemoteLogin?platform=${platform}&schoologyId=${schoologyId}&source=assignment&id=${taskId}`;
    return this.api.get(path).pipe(map((response) => response as any[]));
  }
  getSchoologyRemoteLoginCourse(
    platform: string,
    schoologyId: string,
    courseId: string
  ): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/schoologyRemoteLogin?platform=${platform}&schoologyId=${schoologyId}&source=course&id=${courseId}`;
    return this.api.get(path).pipe(map((response) => response as any[]));
  }
  getContactsByParent(): Observable<ContactModel[]> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/contacts`;
    return this.api
      .get(path)
      .pipe(map((response) => response as ContactModel[]));
  }
  getDashboardByParent(): Observable<DashboardModel> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/dashboard`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getEsyLogin(): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentUsername}/esyRemoteLogin`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  confirmStudent(registerModel: any) {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students/${registerModel.studentId}/confirm`;
    let form = {
      pin: registerModel.pinCode,
      birthDate: registerModel.birthDate,
    };
    return this.api
      .postForms(path, form)
      .pipe(map((response) => response as any));
  }
  addStudent(registerModel: any) {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students/${registerModel.studentId}`;
    let form = {
      pin: registerModel.pinCode,
      birthDate: registerModel.birthDate,
    };
    return this.api
      .postForms(path, form)
      .pipe(map((response) => response as any));
  }
  removeStudent(studentId: any, parentId: any = null) {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students/${studentId}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }
  removeStudent2(studentId: any, parentId: any) {
    let path = `${this.baseUrl}/v1/parents/${parentId}/students/${studentId}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }
  updateEmergencyContact(model: any): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students/`;
    return of(model);
  }
  getParentIdByMisisId(misisId: any): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${misisId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  
  updateRecentStudentMenuByParent(model: string) {    
    let path = `${this.baseUrl}/v1/parents/${this.parentUsername}/recentmenu/${model}`;
    return this.api
      .post(path, {})
      .pipe(map((response) => response as any));
  }
  
  addfavoriteStudentMenuByParent(buttonId: string) {    
    let path = `${this.baseUrl}/v1/parents/${this.parentUsername}/favorites/${buttonId}`;
      return this.api
      .post(path,{})
      .pipe(map((response) => response as any));
  }

  removeFavoriteStudentMenu(buttonId) {    
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/favorites/${buttonId}`;
    return this.api.delete(path).pipe(map((response) => response as any));
  }

  getFavoritesByParent(): Observable<StudentMenuItem[]> {    
    let path = `${this.baseUrl}/v1/parents/${this.parentUsername}/favorites`;
    return this.api
      .get(path)
      .pipe(map((response) => response as StudentMenuItem[]));
  }

  getRecentStudentMenuByParent(): Observable<StudentMenuItem> {    
    let path = `${this.baseUrl}/v1/parents/${this.parentUsername}/recentmenu`;
    return this.api
      .get(path)
      .pipe(map((response) => response as StudentMenuItem));
  }
  
  addTransportationRequest(requestModel: any): Observable<any> {
    let path = `${this.baseUrl}/v1/parents/${this.parentId}/students/${requestModel.studentId}/bus/request`;
    let form = {
      parentName: requestModel.parentName,
      submissionDate: requestModel.submissionDate,
      specialNeedsRequest: requestModel.specialNeedsRequest,
      currentSchoolYear: requestModel.currentSchoolYear,
      nextSchoolYear: requestModel.nextSchoolYear,
      isOptOut: requestModel.isOptOut
    };
    return this.api
      .postForms(path, form)
      .pipe(map((response) => response as any));
  }

  getStudentBusRequestStatus(studentId: any, schoolYear:any): Observable<StudentTransportRequest[]> {    
    let path = `${this.baseUrl}/v1/parents/${this.parentUsername}/students/${studentId}/bus/request/status?schoolYear=${schoolYear}`;
    return this.api
      .get(path)
      .pipe(map((response) => response as StudentTransportRequest[]));
  }  

  saveStudentHealthConsent(consents: IStudentHealthConsent[]): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${this.parentId}/saveStudentHealthConsent`; 
    return this.api.put(path, consents) .pipe(map((response) => response as any));
  }  

  getStudentHealthConsentByParentId(): Observable<IStudentHealthConsent[]> {
    let path = `${this.baseUrl}/v1/students/${this.parentId}/StudentHealthConsent`;
    return this.api
      .get(path)
      .pipe(map((response) => response as IStudentHealthConsent[]));
  }

  getFeatureConfiguration(): Observable<any> {    
    let path = `${this.baseUrl}/v1/parents/${this.parentUsername}/feature/configuration`;
    return this.api
      .get(path)
      .pipe(map((response) => response as any));
  } 

  getFeatureMaintenance(): Observable<any> {    
    let path = `${this.baseUrl}/v1/parents/${this.parentUsername}/feature/maintenance`;
    return this.api
      .get(path)
      .pipe(map((response) => response as any));
  } 

  // getParentSurveysList(): Observable<any> {    
  //   let path = `${this.baseUrl}/v1/surveys`;
  //   return this.api
  //     .get(path)
  //     .pipe(map((response) => response as any));
  // } 

  getParentSurvey(surveyId:string): Observable<any> {    
    let path = `${this.baseUrl}/v1/survey/${surveyId}`;
    return this.api
      .get(path)
      .pipe(map((response) => response as any));
  } 

  getParentSurveysList(): Observable<any> {    
    let path = `${this.baseUrl}/v1/survey/parentId/${this.parentId}/all`;
    return this.api
      .get(path)
      .pipe(map((response) => response as any));
  } 

  addParentSurveyResponses(surveyId, requestModel: CmdAddParentSurveyResponses): Observable<any> {
    let path = `${this.baseUrl}/v1/survey/${surveyId}/parentId/${this.parentId}/submit`;
    return this.api
      .post(path, requestModel)
      .pipe(map((response) => response as any));
  }
}
