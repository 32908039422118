import { Component, OnInit, Input } from '@angular/core';
import { StudentsService } from "src/app/shared/services/students.service";
import { ActivatedRoute } from '@angular/router';
import { Chart, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { IStudentReportGradeLevel } from '../student-testing/models/StudentReportGradeLevel';
import { IStudentResume } from '../../student-list-widget/models/IStudentResume';
import { IStudentMathReport } from '../student-testing/models/StudentMathReport';
import { IStudentELAReport } from '../student-testing/models/StudentELAReport';
import { IStudentReport } from 'src/app/shared/services/student.service.models';
import { StudenttestingdownloadService } from 'src/app/shared/services/studenttestingdownload.service';
import { ISchoolInfo } from '../../student-list-widget/models/ISchoolInfo';
import { IStudentDetails } from '../../student-list-widget/models/IStudentDetails';
import { ProfileService } from "src/app/shared/services/profile.service";
Chart.register(annotationPlugin);

// Custom plugin to add labels to the center of each bar
const centerLabelPlugin = {
  id: 'centerLabelPlugin',
  afterDatasetsDraw(chart: any) {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset: any, datasetIndex: number) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((bar: any, index: number) => {
        const value = dataset.data[index];
        
        // Skip drawing label if the value is 0 or NaN
        if (value === 0 || isNaN(value)) return;

        const centerX = bar.x;
        const centerY = bar.y + (bar.base - bar.y) / 2;

        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'black'; 
        ctx.fillText('+' + value, centerX, centerY);
        ctx.restore();
      });
    });
  }
};
Chart.register(centerLabelPlugin);

@Component({
  selector: 'app-student-testing-download',
  templateUrl: './student-testing-download.component.html',
  styleUrls: ['./student-testing-download.component.scss']
})
export class StudentTestingDownloadComponent implements OnInit {

  private chart: Chart;
  reportId: string = "CAASPP";
  reportYear: string = null;
  academicYear: string = null;
  iReadyTest: string = null;
  iReadyTestType: string[] = ['Reading', 'Math'];
  studentId: string = null;
  reportType: string = null;
  reportResponse: IStudentReport = null;
  message: any = '';
  student: IStudentDetails = null;
  lastUpdated: any;
  pfts: any = null;
  olsat: any = null;
  ap: any = null;
  psat: any = null;
  iReadyELA: IStudentELAReport [] = [];
  iReadyMath: IStudentMathReport [] = [];
  iReadyBoyELA: IStudentELAReport = null;
  iReadyMoyELA: IStudentELAReport = null;
  iReadyEoyELA: IStudentELAReport = null;
  tempIReadyMoyELATest: IStudentELAReport[] = [];
  iReadyBoyMath: IStudentMathReport = null;
  iReadyMoyMath: IStudentMathReport = null;
  iReadyEoyMath: IStudentMathReport = null;
  tempIReadyMoyMathTest: IStudentMathReport[] = [];
  minScoreValueELA: number;
  maxScoreValueELA: number;
  minScoreValueMath: number;
  maxScoreValueMath: number;
  minGradeLevelELA: number;
  maxGradeLevelELA: number;
  midOnGradeLevelELA: number;
  minGradeLevelMath: number;
  maxGradeLevelMath: number;
  midOnGradeLevelMath: number;
  annualTypicalGrowthMeasureELA: number;
  annualStretchGrowthMeasureELA: number;
  annualTypicalGrowthMeasureMath: number;
  annualStretchGrowthMeasureMath: number;
  scoreRange: any;
  stepSize: any; 
  showArrowELA: boolean = false;
  showSquareELA: boolean = false
  showDashELA: boolean = false;
  showArrowMath: boolean = false;
  showSquareMath: boolean = false
  showDashMath: boolean = false;
  latestMoyCompletionDate: Date;
  showiReadyReport: boolean = false;
  isResponseReceivedELA: boolean = null;
  isResponseReceivedMath: boolean = null;
  showGrowthLines:boolean = true;
  lexileMeasure: any;
  lexileRange: any;
  iReadyReportFlag: boolean = false;
  lowerMOYELA: boolean = false;  
  lowerEOYELA: boolean = false;
  tempOverallScoreMOYELA: number;
  tempOverallScoreEOYELA: number;
  lowerMOYMath: boolean = false;  
  lowerEOYMath: boolean = false;
  tempOverallScoreMOYMath: number;
  tempOverallScoreEOYMath: number;
  schoolInfo: ISchoolInfo=null;
  studentName:string='';
  gradeDescription:string='';
   //iReady Graph configuration
   public barChartData: ChartDataset[] = [
    { data: [], label: '' , backgroundColor: 'rgba(54, 162, 235, 0.5)', hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)', barThickness: 60},
    { data: [], label: '', backgroundColor: 'rgba(0, 100, 0, 0.5)', hoverBackgroundColor: 'rgba(0, 100, 0, 0.8)', barThickness: 60},
  ];

  public barChartOptions: ChartOptions;
  public barChartLabels: string[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [annotationPlugin, centerLabelPlugin];
  @Input() inputStudentId: string = '';
  @Input() inputYear: string = '';
  @Input() inputTestType: string = '';
  @Input() isFromTeacher: string = '';
  constructor(
    private studentService: StudentsService,
    private studenttestingdownload:StudenttestingdownloadService,
    private route:ActivatedRoute,
    private profileService:ProfileService
  ) { }

  ngOnInit(): void { 
    if (this.isFromTeacher == "teacher") {
      this.reportYear = this.inputYear;
      this.iReadyTest = this.inputTestType;
      this.studentId = this.inputStudentId;
    }
    else{
    let userRole = this.profileService.identity.userType;
    if(userRole == 'Parent'){

    }
    else if(userRole == 'Teacher')
    {

    }
    this.route.queryParams.subscribe(params => {
      const encodedData = params['data'];
      if (encodedData) {
        try {
          const decodedData = JSON.parse(atob(encodedData));
          this.reportYear = decodedData.reportYear;
          this.iReadyTest=decodedData.iReadyTest;
          this.studentId=decodedData.studentId;
          console.log('Received reportYear:',  this.reportYear);
          console.log('Received iReadyTest:',  this.iReadyTest);
          console.log('Received studentId:',  this.studentId);
        }
        catch (error) {
          console.error("Failed to decode parameters", error);
        }
      }
     

    });
  }
this.getStudentDetails();

if(this.iReadyTest==='Reading')
{
  this.getIReadyELA();
}else
{
this.getIReadyMath();
}
    
  }

  getStudentDetails():void
  {
    this.studentService.getStudentById(this.studentId).subscribe((student:IStudentDetails) => {
      this.student = student;
      
      if(this.student)
      {
        this.studentName =
  this.student.firstName + " "  + this.student.lastName;
        this.getGradeDesc(this.student)
      }
    });
  this.studentService.getStudentSchoolInfo(this.studentId).subscribe((schoolInfo:ISchoolInfo) => {
    this.schoolInfo = schoolInfo;
  });
  
 
  }
  getGradeDesc(student:IStudentDetails) {
    switch (student?.gradeCode) {
      case "PK":
        this.gradeDescription = "PreKindergartener @";
        break;
      case "K":
        this.gradeDescription = "Kindergartener @";
        break;
      case "1":
        this.gradeDescription = "1st Grader Student @";
        break;
      case "2":
        this.gradeDescription = "2nd Grader Student @";
        break;
      case "3":
        this.gradeDescription = "3rd Grader Student @";
        break;
      default:
        this.gradeDescription = `${student.gradeCode}th Grader Student @`;
    }
  }
  getIReadyELA() {       
    this.generateAcademicYear();
    this.isResponseReceivedELA = false;

    this.studentService
      .getStudentIReadyELAReport(this.studentId, this.academicYear)
      .subscribe((response: IStudentELAReport[]) => {
        if (response && response.length > 0) {
          this.message = '';
          this.iReadyELA = response;
          console.log("ELA Response: " + response);
          this.processIReadyELAResponse(response);
          this.getLexile();
          this.isResponseReceivedELA = true; 
        } else {
          this.isResponseReceivedELA = false;
          this.message = "";
          console.log("No ELA Response received.");
        }
      },
      error => {
        console.error("Error fetching ELA report: ", error);
        this.isResponseReceivedELA = false;
        this.message = "";
      });
  }  

  getIReadyMath() {
    this.generateAcademicYear();
    this.isResponseReceivedMath = false; 

    this.studentService
      .getStudentIReadyMathReport(this.studentId, this.academicYear)
      .subscribe(
        (response: IStudentMathReport[]) => {
          if (response && response.length > 0) {
            this.message = '';
            this.iReadyMath = response;
            console.log("Math Response: " + response);
            this.processIReadyMathResponse(response);
            this.isResponseReceivedMath = true; 
          } else {
            this.isResponseReceivedMath = false; 
            this.message = "";
            console.log("No Math Response received.");
          }
        },
        error => {
          console.error("Error fetching Math report: ", error);
          this.isResponseReceivedMath = false; 
          this.message = "";
        }
      );
  }

  processIReadyELAResponse(response){
    this.showiReadyReport = true;
    this.resetReport();

    response.forEach((item, index) => {
      console.log(`Object ${index}:`, item);   

      if (item.normingWindow.startsWith('Fall') && item.baselineDiagnostic === 'Y') {
        this.iReadyBoyELA = item;
        this.barChartData[0].data[0] = parseInt(item.overallScaleScore);
        this.barChartData[1].data[0] = parseInt(item.diagnosticGain);
        this.barChartLabels[0] = 'BOY (' + item.completionDate + ')'
      } else if (item.normingWindow.startsWith('Spring') && item.mostRecentDiagnosticYTD === 'Y') {
        this.iReadyEoyELA = item;
        this.barChartData[0].data[2] = parseInt(item.overallScaleScore) - parseInt(item.diagnosticGain);
        this.barChartData[1].data[2] = parseInt(item.diagnosticGain);
        this.barChartLabels[2] = 'EOY (' + item.completionDate + ')'
      } else if(item.normingWindow.startsWith('Winter')) {
          this.tempIReadyMoyELATest.push(item);
      }  
      
      //Get the Stretch, Typical, and Mid On Grade Level values
      if(item.mostRecentDiagnosticYTD === 'Y'){      
        if(item.annualTypicalGrowthMeasure){
          this.annualTypicalGrowthMeasureELA = item.annualTypicalGrowthMeasure;
        }
        if(item.annualStretchGrowthMeasure){
          this.annualStretchGrowthMeasureELA = item.annualStretchGrowthMeasure;
        }
      }
    });

    if(this.tempIReadyMoyELATest.length == 1){
      this.iReadyMoyELA = this.tempIReadyMoyELATest[0];
      this.barChartData[0].data[1] = parseInt(this.tempIReadyMoyELATest[0].overallScaleScore) - parseInt(this.tempIReadyMoyELATest[0].diagnosticGain);
      this.barChartData[1].data[1] = parseInt(this.tempIReadyMoyELATest[0].diagnosticGain);
      this.barChartLabels[1] = 'MOY (' + this.tempIReadyMoyELATest[0].completionDate + ')'
    }
    else if (this.tempIReadyMoyELATest.length !=0){
      let latestMoyTest: IStudentELAReport = null;
      this.tempIReadyMoyELATest.forEach((moy, index) => {
        console.log(`Moy at index ${index}:`, moy);

        if (!latestMoyTest || moy.completionDate > latestMoyTest.completionDate) {
          latestMoyTest = moy; 
        }
      });

      this.iReadyMoyELA = latestMoyTest;
      this.barChartData[0].data[1] = parseInt(latestMoyTest.overallScaleScore) - parseInt(latestMoyTest.diagnosticGain);
      this.barChartData[1].data[1] = parseInt(latestMoyTest.diagnosticGain);
      this.barChartLabels[1] = 'MOY (' + latestMoyTest.completionDate + ')'      
    }    

    //Assign ordinal values to percentiles
    if (this.iReadyBoyELA && this.iReadyBoyELA.percentile != null) {
      this.iReadyBoyELA.percentile = this.getOrdinalPercentile(parseInt(this.iReadyBoyELA.percentile));
    }
    
    if (this.iReadyMoyELA && this.iReadyMoyELA.percentile != null) {
      this.iReadyMoyELA.percentile = this.getOrdinalPercentile(parseInt(this.iReadyMoyELA.percentile));
    }
    
    if (this.iReadyEoyELA && this.iReadyEoyELA.percentile != null) {
      this.iReadyEoyELA.percentile = this.getOrdinalPercentile(parseInt(this.iReadyEoyELA.percentile));
    }

    //Calculate Typical and Stretch Growth using the BOY
    if(this.annualTypicalGrowthMeasureELA){
      this.annualTypicalGrowthMeasureELA = Number(this.annualTypicalGrowthMeasureELA) + Number(this.iReadyBoyELA.overallScaleScore);
    }

    if(this.annualStretchGrowthMeasureELA){
      this.annualStretchGrowthMeasureELA = Number(this.annualStretchGrowthMeasureELA) + Number(this.iReadyBoyELA.overallScaleScore);
    }      

    console.log("Typical Growth", this.annualTypicalGrowthMeasureELA);
    console.log("Stretch Growth", this.annualStretchGrowthMeasureELA);

    this.assignIReadyELAReportMinMaxValue(); 
    this.checkGrowthLineDisplayELA();

    //Check for cases where MOY or EOY are less than the minimum scale score
    if (this.isNumber(this.barChartData[0].data[1])) {
      if (this.barChartData[0].data[1] < this.minScoreValueELA) {
        this.tempOverallScoreMOYELA = this.barChartData[0].data[1];
        this.barChartData[0].data[1] = this.minScoreValueELA + 2;
        this.lowerMOYELA = true;
      }
    }

    if (this.isNumber(this.barChartData[0].data[2])) {
      if(this.barChartData[0].data[2] < this.minScoreValueELA){
        this.tempOverallScoreEOYELA = this.barChartData[0].data[2];
        this.barChartData[0].data[2] = this.minScoreValueELA + 2;
        this.lowerEOYELA= true;
      }
    }

    this.getIReadyELAReportGradeLevel(this.iReadyBoyELA.grade);   
    
    console.log("Completion Date", this.iReadyBoyELA?.completionDate);

    console.log("Boy ELA:", this.iReadyBoyELA);
    console.log("Eoy ELA:", this.iReadyEoyELA);
    console.log("Moy ELA:", this.iReadyMoyELA);
  }
  
  isNumber(value: any): value is number {
    return typeof value === 'number';
  }

  assignIReadyELAReportMinMaxValue(){
    this.minScoreValueELA = Number(this.iReadyBoyELA.overallScaleScore);
    this.minScoreValueELA = this.minScoreValueELA - 40;
    this.minScoreValueELA = Math.floor(this.minScoreValueELA / 10) * 10;      
    this.maxScoreValueELA = this.minScoreValueELA + 120;   

    console.log("Min score ELA: ", this.minScoreValueELA);
    console.log("Max score ELA: ", this.maxScoreValueELA);
  }

  generateELALegends(){
    if(this.minGradeLevelELA >= this.maxScoreValueELA){
      this.showArrowELA = true;
      this.showSquareELA = false;
    }
    else{ 
      this.showArrowELA = false;
      this.showSquareELA = true;
    }

    if(this.midOnGradeLevelELA > this.maxScoreValueELA){
      this.showDashELA = false;
    }else {
      this.showDashELA = true;
    }

    console.log("Show Arrow ELA: ", this.showArrowELA);
    console.log("Show Dash ELA:", this.showSquareELA);
    console.log("Show Dash ELA:", this.showDashELA);
  }

  generateMathLegends(){
    if(this.minGradeLevelMath >= this.maxScoreValueMath){
      this.showArrowMath = true;
      this.showSquareMath = false;
    }
    else{ 
      this.showArrowMath = false;
      this.showSquareMath = true;
    }

    if(this.midOnGradeLevelMath > this.maxScoreValueMath){
      this.showDashMath = false;
    }else {
      this.showDashMath = true;
    }

    console.log("Show Arrow Math: ", this.showArrowMath);
    console.log("Show Square Math:", this.showSquareMath);
    console.log("Show Dash Math:", this.showDashMath);
  }

  checkGrowthLineDisplayELA(){
    if((this.annualTypicalGrowthMeasureELA > this.maxScoreValueELA || this.annualTypicalGrowthMeasureELA < this.minScoreValueELA) &&
      (this.annualStretchGrowthMeasureELA > this.maxScoreValueELA || this.annualStretchGrowthMeasureELA < this.minScoreValueELA)){
      this.showGrowthLines = false;
    } 
  }

  checkGrowthLineDisplayMath(){
    if((this.annualTypicalGrowthMeasureMath > this.maxScoreValueMath|| this.annualTypicalGrowthMeasureMath < this.minScoreValueMath) &&
    (this.annualStretchGrowthMeasureMath > this.maxScoreValueMath || this.annualStretchGrowthMeasureMath < this.minScoreValueMath)){
      this.showGrowthLines = false;
    }   
  }

  getLexile(){
    this.iReadyELA.forEach((latestTest, index) => {
      if (latestTest.mostRecentDiagnosticYTD === 'Y') {
        this.lexileMeasure = latestTest.lexileMeasure; 
        this.lexileRange = latestTest.lexileRange
      }
    });
  }

  processIReadyMathResponse(response){
    this.showiReadyReport = true;
    this.resetReport();
    
    response.forEach((item, index) => {
      console.log(`Object ${index}:`, item);

      if (item.normingWindow.startsWith('Fall') && item.baselineDiagnostic === 'Y') {
        this.iReadyBoyMath = item;
        this.barChartData[0].data[0] = parseInt(item.overallScaleScore);
        this.barChartData[1].data[0] = parseInt(item.diagnosticGain);
        this.barChartLabels[0] = 'BOY (' + item.completionDate + ')';
      } else if (item.normingWindow.startsWith('Spring') && item.mostRecentDiagnosticYTD === 'Y') {
        this.iReadyEoyMath = item;
        this.barChartData[0].data[2] = parseInt(item.overallScaleScore) - parseInt(item.diagnosticGain);
        this.barChartData[1].data[2] = parseInt(item.diagnosticGain);
        this.barChartLabels[2] = 'EOY (' + item.completionDate + ')';
      } else if(item.normingWindow.startsWith('Winter')) {
          this.tempIReadyMoyMathTest.push(item);
      }  
      
      //Get the Stretch, Typical, and Mid On Grade Level values
      if(item.mostRecentDiagnosticYTD === 'Y'){      
        if(item.annualTypicalGrowthMeasure){
          this.annualTypicalGrowthMeasureMath = item.annualTypicalGrowthMeasure;
        }
        if(item.annualStretchGrowthMeasure){
          this.annualStretchGrowthMeasureMath = item.annualStretchGrowthMeasure;
        }
      }   
    });

    if(this.tempIReadyMoyMathTest.length == 1){
      this.iReadyMoyMath = this.tempIReadyMoyMathTest[0];
      this.barChartData[0].data[1] = parseInt(this.tempIReadyMoyMathTest[0].overallScaleScore) - parseInt(this.tempIReadyMoyMathTest[0].diagnosticGain);
      this.barChartData[1].data[1] = parseInt(this.tempIReadyMoyMathTest[0].diagnosticGain);
      this.barChartLabels[1] = 'MOY (' + this.tempIReadyMoyMathTest[0].completionDate + ')';
    }
    else if (this.tempIReadyMoyMathTest.length !=0){
      let latestMoyTest: IStudentMathReport = null;
      this.tempIReadyMoyMathTest.forEach((moy, index) => {
        console.log(`Math Moy at index ${index}:`, moy);

        if (!latestMoyTest || moy.completionDate > latestMoyTest.completionDate) {
          latestMoyTest = moy; 
        }
      });

      this.iReadyMoyMath = latestMoyTest;
      this.barChartData[0].data[1] = parseInt(latestMoyTest.overallScaleScore) - parseInt(latestMoyTest.diagnosticGain)
      this.barChartData[1].data[1] = parseInt(latestMoyTest.diagnosticGain);
      this.barChartLabels[1] = 'MOY (' + latestMoyTest.completionDate + ')';
      
    }    

    //Assign ordinal values to percentiles
    if (this.iReadyBoyMath && this.iReadyBoyMath.percentile != null) {
      this.iReadyBoyMath.percentile = this.getOrdinalPercentile(parseInt(this.iReadyBoyMath.percentile));
    }
    
    if (this.iReadyMoyMath && this.iReadyMoyMath.percentile != null) {
      this.iReadyMoyMath.percentile = this.getOrdinalPercentile(parseInt(this.iReadyMoyMath.percentile));
    }
    
    if (this.iReadyEoyMath && this.iReadyEoyMath.percentile != null) {
      this.iReadyEoyMath.percentile = this.getOrdinalPercentile(parseInt(this.iReadyEoyMath.percentile));
    }

    //Calculate Typical and Stretch Growth using the BOY
    if(this.annualTypicalGrowthMeasureMath){
      this.annualTypicalGrowthMeasureMath = Number(this.annualTypicalGrowthMeasureMath) + Number(this.iReadyBoyMath.overallScaleScore);
    }

    if(this.annualStretchGrowthMeasureMath){
      this.annualStretchGrowthMeasureMath = Number(this.annualStretchGrowthMeasureMath) + Number(this.iReadyBoyMath.overallScaleScore);
    } 

    console.log("Typical Growth", this.annualTypicalGrowthMeasureMath);
    console.log("Stretch Growth", this.annualStretchGrowthMeasureMath);

    this.assignIReadyMathReportMinMaxValue();
    this.checkGrowthLineDisplayMath();

    //Check for cases where MOY or EOY are less than the minimum scale score
    if (this.isNumber(this.barChartData[0].data[1])) {
      if (this.barChartData[0].data[1] < this.minScoreValueMath) {
        this.tempOverallScoreMOYMath = this.barChartData[0].data[1];
        this.barChartData[0].data[1] = this.minScoreValueMath + 2;
        this.lowerMOYMath = true;
      }
    }

    if (this.isNumber(this.barChartData[0].data[2])) {
      if(this.barChartData[0].data[2] < this.minScoreValueMath){
        this.tempOverallScoreEOYMath = this.barChartData[0].data[2];
        this.barChartData[0].data[2] = this.minScoreValueMath + 2;
        this.lowerEOYMath= true;
      }
    }

    this.getIReadyMathReportGradeLevel(this.iReadyBoyMath.grade);    
   
    console.log("Completion Date Math", this.iReadyBoyMath?.completionDate);

    console.log("Boy Math:", this.iReadyBoyMath);
    console.log("Eoy Math:", this.iReadyEoyMath);
    console.log("Moy Math:", this.iReadyMoyMath);
  }

  assignIReadyMathReportMinMaxValue(){
    this.minScoreValueMath = Number(this.iReadyBoyMath.overallScaleScore);
    this.minScoreValueMath = this.minScoreValueMath - 40;
    this.minScoreValueMath = Math.floor(this.minScoreValueMath / 10) * 10;      
    this.maxScoreValueMath = this.minScoreValueMath + 120;   

    console.log("Min score Math: ", this.minScoreValueMath);
    console.log("Max score Math : ", this.maxScoreValueMath);
  }

  initializeIReadyELAChartOptions() {
    this.barChartOptions = {
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          beginAtZero: true,
          min: this.minScoreValueELA,
          max: this.maxScoreValueELA,
          ticks: {
            stepSize: this.maxScoreValueELA - this.minScoreValueELA,
          }
        }
      },
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          callbacks: {
              label: (context) => {
                  const dataIndex = context.dataIndex;
                  
                  const scaleScore = this.barChartData[0].data[dataIndex] as number;
                  const growth = this.barChartData[1].data[dataIndex] as number;

                  let total = scaleScore + growth;

                  //For cases where MOY or EOY are less than the minimum scale score
                  if (dataIndex === 1 && this.lowerMOYELA) {                    
                    total = this.tempOverallScoreMOYELA;
                  } else if (dataIndex === 2 && this.lowerEOYELA) {                      
                    total = this.tempOverallScoreEOYELA;
                  }

                  //For all the other cases, happy path workflow
                  if (context.datasetIndex === 0) {
                    return `Scale Score: ${total}`;
                  } else if (context.datasetIndex === 1) {
                    return `Growth: ${growth}`;
                  }
                return '';
              }
          }
      },
        annotation: {
          annotations: {
            stretchGrowthLine: { // Stretch Growth line
              type: 'line',
              yMin: this.annualStretchGrowthMeasureELA,
              yMax: this.annualStretchGrowthMeasureELA,
              borderColor: 'grey',
              borderWidth: 2,
              borderDash: [5, 5],
              label: {
                content: 'Stretch',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            stretchGrowthValueLabel: { // Stretch Growth line label
              type: 'line',
              scaleID: 'y',
              value: this.annualStretchGrowthMeasureELA,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: this.annualStretchGrowthMeasureELA.toString(),
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            typicalGrowthLine: { // Typical Growth line
              type: 'line',
              yMin: this.annualTypicalGrowthMeasureELA,
              yMax: this.annualTypicalGrowthMeasureELA,
              borderColor: 'grey',
              borderWidth: 2,
              label: {
                content: 'Typical',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            typicalGrowthValueLabel: { // Typical Growth line label
              type: 'line',
              scaleID: 'y',
              value: this.annualTypicalGrowthMeasureELA,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: this.annualTypicalGrowthMeasureELA.toString(),
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            midOnGradeLevelLine: { // Mid On Grade Level line
              type: 'line',
              yMin: this.midOnGradeLevelELA,
              yMax: this.midOnGradeLevelELA,
              borderColor: 'green',
              borderWidth: 2,
              label: {
                content: '',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'green'
              }
            },
            midOnGradeLevelValueLabel: { // Mid On Grade Level line label
              type: 'line',
              scaleID: 'y',
              value: this.midOnGradeLevelELA,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: '',
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'green'
              }
            },
            onGradeLevelBox: { // On Grade Level green zone
              type: 'box',
              xMin: -0.5,
              xMax: 3.5,
              yMin: this.minGradeLevelELA,
              yMax: this.maxGradeLevelELA,
              backgroundColor: 'rgba(0, 128, 0, 0.2)',
              borderWidth: 0,
              borderColor: 'green',
            },
          },
        }
      }
    };
  }

  initializeIReadyMathChartOptions() {
    this.barChartOptions = {
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          beginAtZero: true,
          min: this.minScoreValueMath,
          max: this.maxScoreValueMath,
          ticks: {
            stepSize: this.maxScoreValueMath - this.minScoreValueMath,
          }
        }
      },
      plugins: {
        legend: {
          display: true,
        },
        tooltip: {
          callbacks: {
              label: (context) => {
                  const dataIndex = context.dataIndex;
                  
                  const scaleScore = this.barChartData[0].data[dataIndex] as number;
                  const growth = this.barChartData[1].data[dataIndex] as number;

                  let total = scaleScore + growth;

                  //For cases where MOY or EOY are less than the minimum scale score
                  if (dataIndex === 1 && this.lowerMOYMath) {                    
                    total = this.tempOverallScoreMOYMath;
                  } else if (dataIndex === 2 && this.lowerEOYMath) {                      
                    total = this.tempOverallScoreEOYMath;
                  }

                  //For all the other cases, happy path workflow
                  if (context.datasetIndex === 0) {
                    return `Scale Score: ${total}`;
                  } else if (context.datasetIndex === 1) {
                    return `Growth: ${growth}`;
                  }
                return '';
              }
          }
      },
        annotation: {
          annotations: {
            stretchGrowthLine: { // Stretch Growth line
              type: 'line',
              yMin: this.annualStretchGrowthMeasureMath,
              yMax: this.annualStretchGrowthMeasureMath,
              borderColor: 'grey',
              borderWidth: 2,
              borderDash: [5, 5],
              label: {
                content: 'Stretch',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            stretchGrowthValueLabel: { // Stretch Growth line label
              type: 'line',
              scaleID: 'y',
              value: this.annualStretchGrowthMeasureMath,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: this.annualStretchGrowthMeasureMath.toString(),
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            typicalGrowthLine: { // Typical Growth line
              type: 'line',
              yMin: this.annualTypicalGrowthMeasureMath,
              yMax: this.annualTypicalGrowthMeasureMath,
              borderColor: 'grey',
              borderWidth: 2,
              label: {
                content: 'Typical',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            typicalGrowthValueLabel: { // Typical Growth line label
              type: 'line',
              scaleID: 'y',
              value: this.annualTypicalGrowthMeasureMath,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: this.annualTypicalGrowthMeasureMath.toString(),
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'grey'
              }
            },
            midOnGradeLevelLine: { // Mid On Grade Level line
              type: 'line',
              yMin: this.midOnGradeLevelMath,
              yMax: this.midOnGradeLevelMath,
              borderColor: 'green',
              borderWidth: 2,
              label: {
                content: '',
                enabled: true,
                position: 'start',
                xAdjust: -10,
                yAdjust: -10,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'green'
              }
            },
            midOnGradeLevelValueLabel: { // Mid On Grade Level line label
              type: 'line',
              scaleID: 'y',
              value: this.midOnGradeLevelMath,
              borderColor: 'transparent',
              borderWidth: 0,
              label: {
                content: '',
                enabled: true,
                position: 'start',
                xAdjust: -45,
                yAdjust: 0,
                backgroundColor: 'transparent',
                borderWidth: 0,
                color: 'green'
              }
            },
            onGradeLevelBox: { // On Grade Level green zone
              type: 'box',
              xMin: -0.5,
              xMax: 3.5,
              yMin: this.minGradeLevelMath,
              yMax: this.maxGradeLevelMath,
              backgroundColor: 'rgba(0, 128, 0, 0.2)',
              borderWidth: 0,
              borderColor: 'green',
            },
          },
        }
      }
    };
  }

  getIReadyELAReportGradeLevel(grade: string) {
    this.studentService
      .getStudentIReadyELAGradeLevel(this.studentId, grade)
      .subscribe((response: IStudentReportGradeLevel[]) => {
        response.forEach((item, index) => {
          console.log("Response ELA: " + response);
          console.log(`Object ${index}:`, item);
         
          if(item.reportLabel == "Early"){
            this.minGradeLevelELA = Number(item.lowOverall);
          }

          if(item.reportLabel == "Late"){
            this.maxGradeLevelELA = Number(item.highOverall);
          }

          if(item.reportLabel == "Mid"){
            this.midOnGradeLevelELA = Number(item.lowOverall);
          }
        });  
    
        console.log("Min Grade Level ELA", this.minGradeLevelELA);
        console.log("Max Grade Level ELA", this.maxGradeLevelELA);
        console.log("Mid Grade Level ELA", this.midOnGradeLevelELA);  
        
        // Initialize chart once all the chart data is loaded
        this.generateELALegends();         
        this.initializeIReadyELAChartOptions();
      });      
  }

  getIReadyMathReportGradeLevel(grade: string) {
    this.studentService
      .getStudentIReadyMathGradeLevel(this.studentId, grade)
      .subscribe((response: IStudentReportGradeLevel[]) => {
        response.forEach((item, index) => {
          console.log("Response Math: " + response);
          console.log(`Object ${index}:`, item);
          
          if(item.reportLabel == "Early"){
            this.minGradeLevelMath = Number(item.lowOverall);
          }

          if(item.reportLabel == "Late"){
            this.maxGradeLevelMath = Number(item.highOverall);
          }

          if(item.reportLabel == "Mid"){
            this.midOnGradeLevelMath = Number(item.lowOverall);
          }
        });  

        console.log("Min Grade Level Math", this.minGradeLevelMath);
        console.log("Max Grade Level Math", this.maxGradeLevelMath);
        console.log("Mid Grade Level Math", this.midOnGradeLevelMath);

        // Initialize chart once all the chart data is loaded
        this.generateMathLegends();         
        this.initializeIReadyMathChartOptions();
      });
  }
  generateAcademicYear(){
    const startingYear = parseInt(this.reportYear)-1;
    const endingYear = parseInt(this.reportYear);
    this.academicYear = `${startingYear}-${endingYear}`;
  }

  getOrdinalPercentile(number) {
    const lastDigit = number % 10;
    
    let suffix;
    if (lastDigit === 1 && number !== 11) {
      suffix = 'st';
    } else if (lastDigit === 2 && number !== 12) {
      suffix = 'nd';
    } else if (lastDigit === 3 && number !== 13) {
      suffix = 'rd';
    } else {
      suffix = 'th';
    }

    if(number < 25)
      return number = 'Under 25th';
    else 
      return number + suffix;
  }

  resetReport(){
    // Reset min and max scores for the ELA report data
    this.iReadyBoyELA = null;
    this.iReadyMoyELA = null;
    this.iReadyEoyELA = null;
    this.tempIReadyMoyELATest = [];
    
    // Reset min and max scores for the ELA chart data   
    this.minScoreValueELA = 0;
    this.maxScoreValueELA = 0;
    this.annualTypicalGrowthMeasureELA = 0;
    this.annualStretchGrowthMeasureELA = 0;
    this.barChartData.forEach(dataset => dataset.data = []);
    this.barChartLabels = [];
    this.barChartData = [
      { data: [], label: '' , backgroundColor: 'rgba(54, 162, 235, 0.5)', hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)', barThickness: 60},
      { data: [], label: '', backgroundColor: 'rgba(0, 100, 0, 0.5)', hoverBackgroundColor: 'rgba(0, 100, 0, 0.8)', barThickness: 60},
    ]; 

    // Reset min and max scores for the Math report data
    this.iReadyBoyMath = null;
    this.iReadyMoyMath = null;
    this.iReadyEoyMath = null;
    this.tempIReadyMoyMathTest = [];

    // Reset min and max scores for the Math chart data
    this.minScoreValueMath = 0;
    this.maxScoreValueMath = 0;
    this.annualTypicalGrowthMeasureMath = 0;
    this.annualStretchGrowthMeasureMath = 0;
    this.barChartData.forEach(dataset => dataset.data = []);
    this.barChartLabels = [];
    this.barChartData = [
      { data: [], label: '' , backgroundColor: 'rgba(54, 162, 235, 0.5)', hoverBackgroundColor: 'rgba(54, 162, 235, 0.8)', barThickness: 60},
      { data: [], label: '', backgroundColor: 'rgba(0, 100, 0, 0.5)', hoverBackgroundColor: 'rgba(0, 100, 0, 0.8)', barThickness: 60},
    ];
  }

}
